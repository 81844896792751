import React, { useEffect } from "react";
import BaseRoutes from "./routes/BaseRoutes";
import {BrowserRouter} from "react-router-dom";
import RouteChangeTracker from "./routes/RouteChangeTracker";
import ReactGA from 'react-ga';
import { IntlProvider } from "react-intl";
import en from './languages/en.json'
function App() {

useEffect(() => {
  const cookieAccepted = localStorage.getItem("cookies");
  if(cookieAccepted){
    const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
    ReactGA.initialize(TRACKING_ID);
  }
  // this piece of code checks whether a user has previously agreed to cookie policy.
  // if they have, we simply initialize reactGA.
  //if they haven't well, it means that no "cookies" was found in localstorage. reactGA will not be initialized until they accept..... yad rkhin khuda da wasta bhulin na
}, [])

  return (
    <IntlProvider messages={en} locale="en" defaultLocale="en">
      <BrowserRouter>
          <RouteChangeTracker/>
          <BaseRoutes />
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
