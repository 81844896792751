import Navbar from "../components/Navbar/index";
import Sidebar from "../components/Sidebar/index";
import Footer from "../components/Footer/index";
import React, { useState, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//   component imports

import Loader from "../components/Loader";
import Overlay from "../components/Overlay";
import Cookie from "../components/Cookie";

// import PageNotFound from "../pages/PageNotFound";
//   component imports

// Page imports
const Home = React.lazy(() => import("../pages/Home/Desktop"));
// const AR = React.lazy(() => import("../pages/ARLandingPage/ARLandingPage"));
// const ArViewer = React.lazy(() => import("../pages/ArViewer"));
const Language = React.lazy(() =>
  import("../pages/Home/Desktop/LanguagePage/language")
);
const Service = React.lazy(() => import("../pages/Services/Desktop/index"));
const AboutUS = React.lazy(() => import("../pages/About-US/Desktop/index"));
const ContactUS = React.lazy(() => import("../pages/Contact-us"));
const SEOPage = React.lazy(() =>
  import("../pages/Home/Desktop/SEO Services/seoServices")
);
const HowWeDeliver = React.lazy(() =>
  import("../pages/HowWeDeliver/HowWeDeliver")
);
const Careers = React.lazy(() => import("../pages/Careers/Careers"));
const DevService = React.lazy(() =>
  import("../pages/DetailServicePages/WebDevService/WebDevService")
);
const UnderConstruction = React.lazy(() =>
  import("../pages/UnderConstruction/UnderConstruction")
);
// const AnimationPage = React.lazy(() => import("../pages/AnimationPage/Animation"));
const AuthPage = React.lazy(() => import("../pages/SignUp/SignUp"));
// Page imports

// Technology detail page imports
const AngularTechnology = React.lazy(() =>
  import("../pages/Technologies/Angular/Angular")
);
const LaravelTechnology = React.lazy(() =>
  import("../pages/Technologies/Laravel/Laravel")
);
const ReactTechnology = React.lazy(() =>
  import("../pages/Technologies/React/ReactTechnology")
);
const NodeTechnology = React.lazy(() =>
  import("../pages/Technologies/Node/Node")
);
const VueTechnology = React.lazy(() =>
  import("../pages/Technologies/VueJs/VueJs")
);
const KotlineTechnology = React.lazy(() =>
  import("../pages/Technologies/Kotline/Kotline")
);
const DjangoTechnology = React.lazy(() =>
  import("../pages/Technologies/Django/Django")
);
// Technology detail page imports

// Detail service Page imports
const WebDevService = React.lazy(() =>
  import("../pages/DetailServicePages/WebDevService/WebDevService")
);
const QAService = React.lazy(() =>
  import("../pages/DetailServicePages/QAServices/QAService")
);
const DTService = React.lazy(() =>
  import(
    "../pages/DetailServicePages/DigitalTransformation/DigitalTransformation"
  )
);
const MobileAppDevService = React.lazy(() =>
  import("../pages/DetailServicePages/MobileAppDev/MobileAppDev")
);
const UxDesignService = React.lazy(() =>
  import("../pages/DetailServicePages/UxDesign/UxDesign")
);
const MaintenanceSupport = React.lazy(() =>
  import("../pages/DetailServicePages/MaintenanceSupport/MaintenanceSupport")
);
const BigDataService = React.lazy(() =>
  import("../pages/DetailServicePages/BigDataService/BigDataService")
);
const BlockChainService = React.lazy(() =>
  import("../pages/DetailServicePages/BlockChainService/BlockChainService")
);
const DevopService = React.lazy(() =>
  import("../pages/DetailServicePages/DevopService/DevopService")
);
const TimeAndMaterial = React.lazy(() =>
  import("../pages/DetailServicePages/TimeAndMaterial/TimeAndMaterial")
);
const CouponPage = React.lazy(() => import("../pages/CouponPage/CouponPage"));
const ArServices = React.lazy(() =>
  import("../pages/Services/Desktop/ArServices")
);
const MetverseServices = React.lazy(() =>
  import("../pages/Services/Desktop/MetverseServices")
);
const ThreedModeling = React.lazy(() =>
  import("../pages/Services/Desktop/ThreedModeling")
);
const ModelsPage = React.lazy(() =>
  import("../pages/Services/Desktop/ModelPage")
);
const Portfolio = React.lazy(() => import("../pages/Portfolio"));
// Detail service Page imports

// Game page import
const FallingGems = React.lazy(() => import("../pages/FallingGems"));
// Game page import

// policy pages
const CookiePolicy = React.lazy(() => import("../pages/CookiePolicy"));
// policy pages

// landing pages
const LandingPage = React.lazy(() => import("../pages/LandingPage"));
// landing pages

const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));

const DirectContactUs = React.lazy(() => import("../pages/DirectContact"));
const ItalianDirectContactUs = React.lazy(() =>
  import("../pages/DirectContact/ItalianContactUs")
);

const BaseRoutes = () => {
  const [sidebar, setSidebar] = useState(false);
  const [overlay, setOverlay] = useState(false);
  // const [gameDownload, setGameDownload] = useState(false);
  // const [gamePage, setGamePage] = useState(false);
  // const [cookie, setCookie] = useState(
  //     localStorage.getItem("cookies") ? false : true
  // );
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  return (
    // <div className="flex flex-col h-full pt-28">
    <Suspense fallback={<Loader />}>
      {/* Showing cookie bar */}

      {sidebar ? <Overlay /> : null}
      {showHeaderFooter && (
        // !gamePage &&
        <>
          {/* <Navbar sidebar={sidebar} setSidebar={setSidebar} />
          <Sidebar sidebar={sidebar} setSidebar={setSidebar} /> */}
        </>
      )}

      {/* <div className="flex-grow"> */}
      <Switch>
        <Route path="/" exact>
          <LandingPage />
        </Route>
      </Switch>
      {/* </div> */}

      {/* <div className="flex-shrink-0"> */}
      {/* {showHeaderFooter && <Footer />} */}
      {/* </div> */}
    </Suspense>
    // </div>
  );
};

export default BaseRoutes;
